import React, { useState } from 'react';
import { Row } from 'react-bootstrap';
import { Container } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';


export const SiteWideAlerts = ()  => {

    const [show, setShow] = useState(true);

    return (
      <>
        <Alert show={show} variant="success">
          <Alert.Heading>Site Wide Notice</Alert.Heading>
          <p>
            We're Facing some issues with the contact form. We'll fix it ASAP 😞, 
            Meanwhile you can <a href="mailto:hey@himanshup.dev">Mail me</a> 
            &nbsp;<br /><br /> <Button onClick={() => setShow(false)} variant="outline-success">
              Close
            </Button></p>
        </Alert>
      </>
    );
}
