import {useState, useEffect} from "react";
import { Container, Row, Col } from "react-bootstrap"
import { ArrowRightCircle } from "react-bootstrap-icons"
import TrackVisibility from "react-on-screen";
import headerImg from "../assets/img/header-img.svg"

export const Banner = () => {
    const [loopNum, setLoopNum] = useState(0);
    const [isDeleting, setIsDeleting] = useState(false);
    const toRotate= [ "AI Evangelist", "Machine Learning Enthusiast", "MotorCyclist", "Artisan",];
    const [text, setText] = useState('');
    const [delta, setDelta] = useState(300 - Math.random() * 100)
    const period = 500;
    useEffect(() => {
            let ticker = setInterval(()=>{
                tick();

        }, delta)
        return () =>{ clearInterval(ticker) };
    }, [text])

    const tick=() => {
        let i = loopNum % toRotate.length;
        let fullText=toRotate[i];
        let updatedText=isDeleting? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1)
        setText(updatedText);

        if(isDeleting) {
            setDelta(prevDelta => prevDelta/1)
        }

        if (!isDeleting && updatedText === fullText){
            setIsDeleting(true);
            setDelta(period);

        }else if(isDeleting && updatedText === ''){
            setIsDeleting(false);
            setLoopNum(loopNum + 1)
            setDelta(500);
        }
    }
    return (
        <section className="banner" id="home">
            <Container>
                <Row className="align-items-center">
                    <Col xs={12} md={6} xl={7}>
                        <TrackVisibility>
                        {({isVisible}) =>
                            <div className={isVisible ? "animated__animated animated__bounce" : ""}>
                                <span className="tagline">Welcome to my portfolio</span>
                                <h1>
                                    {`Hi I'm Himanshu`} 
                                </h1>
                                <h2>{`I'm a... `} 
                                    <span className="wrap">{text}</span>
                                </h2>
                                    <p>I am a Technical Project manager working with Tata Technologies in India. I have worked on multiple domains ranging from health care to SaaS. I am an avid AI Evangelist and have implemented a number of algortihm on cloud. Want to learn more about me or want to chat? feel free to contact me at any of the social media platforms.
                                    </p>
                                <button onClick={() =>console.log("connect")}>Let's Connect<ArrowRightCircle size={25} /></button>
                        </div>}
                        </TrackVisibility>
                    </Col>
                    <Col xs={12} md={6} xl={5}>
                        <img src={headerImg} alt="HeaderImg" />
                    </Col>
                </Row>
            </Container>
        </section>
    )
}