import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/project-img1.png";
import projImg2 from "../assets/img/project-img2.png";
import projImg3 from "../assets/img/project-img3.png";

import thp from "../assets/img/thp.png";
import tdp from "../assets/img/header-img.jpg";

import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {

  const opensource = [
   
    {
      title: "Quizzify",
      description: "Android Quiz App",
      imgUrl: tdp,
    },

    {
      title: "TDP",
      description: "The Dice Project - An Open Source Initiative",
      imgUrl: tdp,
    },

    {
      title: "The Xylophone App",
      description: "A fun IOS app project",
      imgUrl: tdp,
    },

    {
      title: "Odin CSS",
      description: "Barebone CMS Framework",
      imgUrl: tdp,
    },

  ];
  const AiProjects = [
    {
      title: "Vision AI",
      description: "AI",
      imgUrl: tdp,
    },
    {
      title: "Hercules",
      description: "ADAS system - Self Driving Cars",
      imgUrl: tdp,
    },
    {
      title: "Harpoon Project",
      description: "Save the ocean initiative [Discontinued]",
      imgUrl: tdp,
    },

  ];
  const SaaSProjects = [

    {
      title: "BearBell",
      description: "CMS based on Ghost",
      imgUrl: tdp,
    },
    {
      title: "RailX",
      description: "SaaS service - Chat app with MultiTenancy[Deprecated]",
      imgUrl: tdp,
    },
    {
      title: "Prisitine CRM",
      description: "Open Source CRM [Obsolete]",
      imgUrl: tdp,
    },
  ];

  return (
    <section className="project" id="project">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>Projects</h2>
                <p>These are the projects that I've built across the web. Some of them are personal projects and some are more of opensource</p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Open Source</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">AI</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">SaaS</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          opensource.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                    <Row>
                        {
                          AiProjects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <Row>
                    {
                          SaaSProjects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                        </Row>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}
